import { Avatar, List, Typography } from "antd";
import React from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UserInfo = () => {
  const { authUser } = useSelector((state: any) => state.auth);

  return (
    <List
      dataSource={[1]}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Link to="/profile/me">
                <Avatar
                  src={
                    authUser?.photo_thumb_url
                      ? authUser.photo_thumb_url
                      : require("assets/images/avatar.svg")
                  }
                  size={64}
                />
              </Link>
            }
            title={
              <Link to="/profile/me" className="gx-fs-lg">
                <Typography.Text ellipsis={true}>
                  {authUser?.full_name}
                </Typography.Text>
              </Link>
            }
            description={
              <Link to="/profile/me" className="gx-font-weight-light">
                <Typography.Text ellipsis={true} className="gx-text-light">
                  <NumberFormat
                    displayType={"text"}
                    value={authUser?.mobile_phone}
                    format="### ### #### ####"
                  />
                </Typography.Text>
                <br />
                <Typography.Text ellipsis={true} className="gx-text-light">
                  {authUser?.email}
                </Typography.Text>
              </Link>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default UserInfo;
