import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import URLSearchParams from "@ungap/url-search-params";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Spin,
  Switch,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";

import {
  AlertError,
  DateSelect,
  InfoView,
  RouteInputs,
  SkeletonLoading,
  UploadPicture,
  VehicleSelector,
} from "../../components";
import { API, getError, Moment } from "../../util";
import { PublicContainer } from "./components";

type IProps = {} & RouteComponentProps;

const Register = (props: IProps) => {
  const { history } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isForDriver = Boolean(params.get("driver"));

  const [resources, setResources] = useState({
    vehicle_types: [],
    states: [],
    brands: [],
    platforms: [],
    insurance_companies: [],
    routes: [],
    cities: [],
  });
  const [error, setError] = useState<string | string[]>("");
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMunicipalities, setLoadingMunicipalities] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const [registerDriver, setRegisterDriver] = useState(isForDriver);
  const [hasInsurance, setHasInsurance] = useState(true);
  const [hasPlatform, setHasPlatform] = useState(true);
  const [userPicture, setUserPicture] = useState<any>();
  const [ineFront, setIneFront] = useState<any>();
  const [ineBack, setIneBack] = useState<any>();
  const [driversLicenseFront, setDriversLicenseFront] = useState<any>();
  const [driversLicenseBack, setDriversLicenseBack] = useState<any>();
  const [circulationCard, setCirculationCard] = useState<any>();
  const [platePicture, setPlatePicture] = useState<any>();
  const [vehiclePicture, setVehiclePicture] = useState<any>();

  const [registerUserForm] = Form.useForm();

  const token = useSelector((state: any) => state.auth.token);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    setLoading(true);
    try {
      const response: any = await API.get("resources");
      if (!response?.data?.states) {
        throw new Error("Error al obtener recursos para registro.");
      }
      setResources(response.data);
    } catch (err) {
      message.error(getError(err));
    }
    setLoading(false);
  };

  const fetchMunicipalities = useCallback(
    async (stateId, clear = false) => {
      if (clear) {
        const values = registerUserForm.getFieldsValue();
        registerUserForm.setFieldsValue({
          ...values,
          municipality_id: null,
        });
      }
      setLoadingMunicipalities(true);
      try {
        const response: any = await API.get("resources/municipalities", {
          params: { state: stateId },
        });
        if (!Array.isArray(response?.data)) {
          throw new Error("Error al obtener datos.");
        }
        setMunicipalities(response.data);
      } catch (error) {
        message.error(getError(error));
      }
      setLoadingMunicipalities(false);
    },
    [registerUserForm]
  );

  const onFinish = async () => {
    setSubmitting(true);
    setError("");
    const formData = new FormData();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    try {
      await registerUserForm.validateFields();
      const values = registerUserForm.getFieldsValue();
      for (const key in values) {
        if (
          Object.prototype.hasOwnProperty.call(values, key) &&
          values[key] !== undefined &&
          (values[key] || values[key] === false)
        ) {
          if (Array.isArray(values[key])) {
            for (var i = 0; i < values[key].length; i++) {
              formData.append(`${key}[]`, JSON.stringify(values[key][i]));
            }
          } else {
            formData.append(key, values[key]);
          }
        }
      }
      formData.set("user_picture", userPicture);
      formData.set("ine_front", ineFront);
      formData.set("ine_back", ineBack);
      formData.set("drivers_license_front", driversLicenseFront);
      formData.set("drivers_license_back", driversLicenseBack);
      formData.set("circulation_card", circulationCard);
      formData.set("plate_picture", platePicture);
      formData.set("vehicle_picture", vehiclePicture);
      const response = await API.post("auth/register", formData, config);
      if (!response?.data?.data?.id) {
        throw new Error("Error al crear usuario.");
      }
      message.success("Se ha creado el usuario");
      history.push(`/verify?id=${response.data.data.id}`);
    } catch (err) {
      scrollIntoView(document.getElementsByClassName("ant-layout-content")[0], {
        block: "start",
        behavior: "smooth",
      });
      setError(getError(err));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (token !== null) {
      setTimeout(() => {
        history.push("/dashboard/shipments");
      }, 100);
    }
  }, [token, history]);

  return (
    <PublicContainer title="Crea tu cuenta">
      <SkeletonLoading loading={loading}>
        <InfoView
          label="Registrando usuario"
          errorTitle="Error al registrar usuario"
        >
          <Spin spinning={submitting} size="large" tip="Guardando">
            <Form
              form={registerUserForm}
              initialValues={{ routes: [{}] }}
              name="registerUserForm"
              onFinish={onFinish}
              requiredMark={false}
              layout="vertical"
              className="gx-login-form"
              scrollToFirstError={true}
            >
              <AlertError
                errorTitle="Error al tratar de guardar"
                error={error}
              />

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email obligatorio",
                  },
                ]}
                hasFeedback
                colon={false}
              >
                <Input
                  type="email"
                  placeholder="Tu email"
                  bordered={false}
                  className="gx-input-underlined"
                />
              </Form.Item>

              <Form.Item
                label="Número de teléfono móvil"
                name="mobile_phone"
                rules={[
                  {
                    required: true,
                    message: "Por favor escribe el teléfono móvil",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Debe contener solamente 10 números",
                  },
                ]}
                hasFeedback
                colon={false}
              >
                <Input
                  placeholder="Número de teléfono móvil"
                  bordered={false}
                  className="gx-input-underlined"
                />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Contraseña obligatoria",
                  },
                ]}
                hasFeedback
                colon={false}
              >
                <Input.Password
                  placeholder="Tu contraseña"
                  bordered={false}
                  className="gx-input-underlined"
                />
              </Form.Item>

              <Form.Item
                label="Repite la contraseña"
                name="password_confirmation"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Repetir contraseña obligatorio",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Las contraseñas no coinciden");
                    },
                  }),
                ]}
                hasFeedback
                colon={false}
              >
                <Input.Password
                  placeholder="Repite la contraseña"
                  bordered={false}
                  className="gx-input-underlined"
                />
              </Form.Item>

              <Form.Item
                label="Nombre(s)"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Nombre obligatorio",
                  },
                ]}
                hasFeedback
                colon={false}
              >
                <Input
                  placeholder="Nombre(s)"
                  bordered={false}
                  className="gx-input-underlined"
                />
              </Form.Item>

              <Form.Item
                label="Apellido(s)"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Apellido obligatorio",
                  },
                ]}
                hasFeedback
                colon={false}
              >
                <Input
                  placeholder="Apellido(s)"
                  bordered={false}
                  className="gx-input-underlined"
                />
              </Form.Item>

              <Form.Item name="gender" label="Sexo">
                <Radio.Group>
                  <Radio value={0}>Femenino</Radio>
                  <Radio value={1}>Masculino</Radio>
                </Radio.Group>
              </Form.Item>

              <DateSelect
                form={registerUserForm}
                name="birthdate"
                label="Fecha del nacimiento"
                required={true}
                requiredMessage="Por favor escribe fecha de nacimiento"
                max={Moment().format("YYYY-MM-DD")}
                bordered={false}
              />

              <UploadPicture
                name="user_picture"
                label="Foto de perfil"
                buttonUpload="Subir tu foto"
                onFinish={setUserPicture}
              />

              <Form.Item
                name="register_driver"
                label="¿Quieres ser conductor (Yolo)?"
                initialValue={registerDriver}
                hasFeedback={false}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Sí"
                  unCheckedChildren="No"
                  onChange={(checked: boolean, e: Event) =>
                    setRegisterDriver(checked)
                  }
                />
              </Form.Item>

              {registerDriver ? (
                <>
                  <Alert
                    message="Para registrarte como Yolo"
                    description="Ten a la mano tu licencia de conducir vigente, tu INE, tu tarjeta de circulación y tu automóvil."
                    type="info"
                    showIcon
                  />

                  <Form.Item name="state_id" label="Estado">
                    <select
                      onChange={(event) =>
                        fetchMunicipalities(event?.target?.value, true)
                      }
                      className="ant-input gx-w-100 ant-input-borderless gx-input-underlined"
                    >
                      <option hidden>Selecciona un estado</option>
                      {resources?.states.map((type: any, index: number) => (
                        <option key={`${index}`} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </Form.Item>

                  <Form.Item
                    name="municipality_id"
                    label="Municipio / Alcaldía"
                  >
                    {loadingMunicipalities ? (
                      <div className="gx-text-center gx-py-3 gx-text-grey">
                        <LoadingOutlined /> Cargando municipios
                      </div>
                    ) : (
                      <select className="ant-input gx-w-100 ant-input-borderless gx-input-underlined">
                        <option hidden>Selecciona un municipio</option>
                        {municipalities.map((municipality: any) => (
                          <option
                            key={`municipality${municipality.id}`}
                            value={municipality.id}
                          >
                            {municipality.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </Form.Item>

                  <Form.Item
                    name="has_platform"
                    label="¿Conduces actualmente para otra compañía?"
                    rules={[
                      {
                        required: true,
                        message: "Seleccionar respuesta",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e: any) =>
                        setHasPlatform(Boolean(e?.target?.value))
                      }
                    >
                      <Radio value={1}>Si</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {hasPlatform ? (
                    <Form.Item
                      label="Selecciona las plataformas"
                      name="platforms"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Seleccionar plataforma",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        options={resources.platforms.map((item: any) => {
                          return { label: item.name, value: item.id };
                        })}
                      />
                    </Form.Item>
                  ) : null}

                  <RouteInputs
                    form={registerUserForm}
                    name="routes"
                    routes={resources.routes}
                  />

                  <Form.Item
                    name="vehicle_type_id"
                    label="Tipo de vehículo"
                    rules={[
                      {
                        required: true,
                        message: "Tipo de vehículo es requerido",
                      },
                    ]}
                  >
                    <Radio.Group>
                      {resources.vehicle_types.map(
                        (item: any, index: number) => (
                          <Radio value={item.id} key={`vehicleType${index}`}>
                            {item.name}
                          </Radio>
                        )
                      )}
                    </Radio.Group>
                  </Form.Item>

                  <VehicleSelector
                    form={registerUserForm}
                    brands={resources?.brands ? resources.brands : []}
                    bordered={false}
                  />

                  <Form.Item name="vehicle_year" label="Año del vehículo">
                    <InputNumber
                      placeholder="4 dígitos"
                      className="gx-input-underlined gx-w-100"
                    />
                  </Form.Item>

                  <Form.Item
                    name="has_insurance"
                    label="¿Cuentas con seguro vigente contra accidentes?"
                    rules={[
                      {
                        required: true,
                        message: "Seleccionar respuesta",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e: any) =>
                        setHasInsurance(Boolean(e?.target?.value))
                      }
                    >
                      <Radio value={1}>Si</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {hasInsurance ? (
                    <Form.Item
                      label="¿Cual es la compañía de tu seguro?"
                      name="insurance_company_id"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Seleccionar compañía",
                        },
                      ]}
                    >
                      <select className="ant-input ant-input-borderless gx-input-underlined gx-w-100">
                        <option hidden>Elige una aseguradora</option>
                        {resources?.insurance_companies.map(
                          (company: any, index: number) => (
                            <option key={`${index}`} value={company.id}>
                              {company.name}
                            </option>
                          )
                        )}
                      </select>
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    name="has_drivers_license"
                    label="¿Cuentas con licencia de conducir vigente?"
                    rules={[
                      {
                        required: true,
                        message: "Respuesta obligatoria",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={1}>Si</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <UploadPicture
                    name="ine_front"
                    label="Foto de INE frontal"
                    buttonUpload="Subir INE frontal"
                    onFinish={setIneFront}
                    required={true}
                    requiredMessage="La foto de INE frontal es obligatoria"
                  />

                  <UploadPicture
                    name="ine_back"
                    label="Foto de INE trasera"
                    buttonUpload="Subir INE trasera"
                    onFinish={setIneBack}
                    required={true}
                    requiredMessage="La foto de INE trasera es obligatoria"
                  />

                  <UploadPicture
                    name="drivers_license_front"
                    label="Foto de licencia de conducir delantera"
                    buttonUpload="Subir licencia de conducir delantera"
                    onFinish={setDriversLicenseFront}
                    required={true}
                    requiredMessage="La foto de licencia de conducir delantera es obligatoria"
                  />

                  <UploadPicture
                    name="drivers_license_back"
                    label="Foto de licencia de conducir trasera"
                    buttonUpload="Subir licencia de conducir trasera"
                    onFinish={setDriversLicenseBack}
                    required={true}
                    requiredMessage="La foto de licencia de conducir trasera es obligatoria"
                  />

                  <UploadPicture
                    name="circulation_card"
                    label="Foto de tarjeta de circulación"
                    buttonUpload="Subir tarjeta de circulación"
                    onFinish={setCirculationCard}
                    required={true}
                    requiredMessage="La foto de tarjeta de circulación es obligatoria"
                  />

                  <UploadPicture
                    name="plate_picture"
                    label="Foto de placa"
                    buttonUpload="Subir placa"
                    onFinish={setPlatePicture}
                    required={true}
                    requiredMessage="La foto de placa es obligatoria"
                  />

                  <Form.Item
                    label="Número de placa"
                    name="plate"
                    rules={[
                      {
                        required: true,
                        message: "Número obligatorio",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder="Número de placa"
                      bordered={false}
                      className="gx-input-underlined"
                    />
                  </Form.Item>

                  <UploadPicture
                    name="vehicle_picture"
                    label="Foto del vehículo"
                    buttonUpload="Subir foto"
                    onFinish={setVehiclePicture}
                    required={true}
                    requiredMessage="La foto del vehículo es obligatoria"
                  />
                </>
              ) : null}

              <Form.Item
                name="agree"
                valuePropName="checked"
                rules={[
                  () => ({
                    validator(_, value) {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(
                            "Debes aceptar para poder registrarte"
                          );
                    },
                  }),
                ]}
              >
                <Checkbox>
                  He leído y acepto los{" "}
                  <a
                    href="https://www.yolo.mx/terminos-y-condiciones/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    términos y condiciones de uso{" "}
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="agree_notice"
                valuePropName="checked"
                rules={[
                  () => ({
                    validator(_, value) {
                      return value
                        ? Promise.resolve()
                        : Promise.reject(
                            "Debes aceptar para poder registrarte"
                          );
                    },
                  }),
                ]}
              >
                <Checkbox>
                  He leído y acepto el{" "}
                  <a
                    href="https://www.yolo.mx/aviso-de-privacidad/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aviso de privacidad{" "}
                  </a>
                </Checkbox>
              </Form.Item>

              <Button
                type="primary"
                icon={<CheckOutlined />}
                className="gx-my-4"
                htmlType="submit"
                block
                size="large"
              >
                Regístrate
              </Button>
            </Form>
          </Spin>
        </InfoView>
      </SkeletonLoading>

      <div className="gx-py-2 gx-text-center">
        ¿Ya tienes cuenta?{" "}
        <Link to="/login" className="gx-text-primary">
          Inicia sesión
        </Link>
      </div>
    </PublicContainer>
  );
};

export default Register;
