import {
  AuditOutlined,
  CalendarOutlined,
  CarOutlined,
  CommentOutlined,
  CreditCardOutlined,
  DiffOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { userLogout } from "../../../redux/actions/Auth";
import { UserInfo } from "../../../components";

const RightSidebarContent = () => {
  const dispatch = useDispatch();
  const { pathname } = useSelector((state: any) => state.settings);
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme="light"
      mode="inline"
    >
      <Menu.ItemGroup
        key="main-menu"
        className="gx-menu-group"
        title={<UserInfo />}
      >
        <Menu.Item key="profile/me" className="gx-border-bottom">
          <Link to="/profile/me">
            <UserOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Perfil</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboard/shipments" className="gx-border-bottom">
          <Link to="/dashboard/shipments">
            <CalendarOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Mis envíos</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboard/trips" className="gx-border-bottom">
          <Link to="/dashboard/trips">
            <CarOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Mis viajes</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="helpdesk/list" className="gx-border-bottom">
          <Link to="/helpdesk/list">
            <CommentOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Contacto</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="page/faqs" className="gx-border-bottom">
          <Link to="/page/faqs">
            <QuestionCircleOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Preguntas frecuentes</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="page/payment-methods" className="gx-border-bottom">
          <Link to="/page/payment-methods">
            <CreditCardOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Metodos de pago</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="notice" className="gx-border-bottom">
          <a
            href="https://www.yolo.mx/aviso-de-privacidad/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AuditOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Aviso de privacidad</span>
          </a>
        </Menu.Item>
        <Menu.Item key="terms" className="gx-border-bottom">
          <a
            href="https://www.yolo.mx/terminos-y-condiciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DiffOutlined className="gx-text-primary" />
            <span className="gx-text-dark">Términos y condiciones</span>
          </a>
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => dispatch(userLogout())}>
          <LogoutOutlined className="gx-text-primary" />
          <span className="gx-text-dark">Cerrar sesión</span>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};

export default RightSidebarContent;
