import {
  CarOutlined,
  CodeSandboxOutlined,
  CommentOutlined,
  DashboardOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CustomScrollbars } from "../../../util";
import SidebarLogo from "./SidebarLogo";

const SidebarContent = () => {
  const { pathname } = useSelector((state: any) => state.settings);
  const { authUser } = useSelector((state: any) => state.auth);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline"
          >
            <Menu.ItemGroup key="main-menu" className="gx-menu-group" title="">
              <Menu.Item key="dashboard">
                <Link to="/dashboard/shipments">
                  <DashboardOutlined />
                  <span>Track</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="publish">
                <Link
                  to={
                    authUser?.is_driver ? `/trip/publish` : "/trip/invitation"
                  }
                >
                  <CarOutlined />
                  <span>Publicar</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="shipment">
                <Link to="/shipment/start">
                  <CodeSandboxOutlined />
                  <span>Enviar</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="helpdesk">
                <Link to="/helpdesk/list">
                  <CommentOutlined />
                  <span>Chat</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="profile">
                <Link to="/profile/me">
                  <UserOutlined />
                  <span>Perfil</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default SidebarContent;
