import { Layout } from "antd";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { RightSidebar, Sidebar, Tabbar, Topbar } from "../../components";
import { App } from "../../routes";

const MainApp = () => {
  const match = useRouteMatch();

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Topbar />
        <Layout.Content className="gx-layout-content">
          <App match={match} />
        </Layout.Content>
        <Tabbar />
      </Layout>
      <RightSidebar />
    </Layout>
  );
};
export default MainApp;
