import { EditOutlined } from "@ant-design/icons";
import { List, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

type IProps = {
  addresses: any[];
  footer?: React.ReactNode;
};

const AddressesList = (props: IProps) => {
  const { addresses = [], footer = null } = props;

  return (
    <List
      className="gx-w-100"
      dataSource={addresses}
      rowKey="id"
      renderItem={(item: any, index: number) => (
        <List.Item
          actions={[
            <Link
              to={`/profile/address/${item?.id}/edit`}
              className="ant-btn ant-btn-icon-only"
            >
              <EditOutlined />
            </Link>,
          ]}
        >
          <List.Item.Meta
            title={
              <Typography.Text
                ellipsis={true}
                className="gx-fs-lg"
                style={{ lineHeight: 2 }}
              >
                {item?.label}
              </Typography.Text>
            }
            description={
              <span className="gx-d-block gx-mt-2 gx-font-weight-light">
                <div className="gx-text-light gx-d-block">{item?.address}</div>
              </span>
            }
          />
        </List.Item>
      )}
      footer={footer}
    />
  );
};

export default AddressesList;
