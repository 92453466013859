import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import MainApp from "../containers/App/MainApp";
import Login from "../containers/Auth/Login";
import Quote from "../containers/Auth/Quote";
import Recovery from "../containers/Auth/Recovery";
import Register from "../containers/Auth/Register";
import Reset from "../containers/Auth/Reset";
import VerifyPhone from "../containers/Auth/VerifyPhone";
import { setInitUrl } from "../redux/actions/Auth";

const RestrictedRoute = ({
  component: Component,
  location,
  token,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const Root = () => {
  const dispatch = useDispatch();
  const { token, initURL } = useSelector((state: any) => state.auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  }, [dispatch, initURL, location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname === "/") {
      if (token === null) {
        history.push("/login");
      } else if (initURL === "" || initURL === "/" || initURL === "/login") {
        history.push("/dashboard/shipments");
      } else {
        history.push(initURL);
      }
    }
  }, [token, initURL, location, history]);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/recovery" component={Recovery} />
      <Route exact path="/quote" component={Quote} />
      <Route exact path="/reset" component={Reset} />
      <Route exact path="/verify" component={VerifyPhone} />
      <RestrictedRoute
        path={match.url}
        token={token}
        location={location}
        component={MainApp}
      />
    </Switch>
  );
};

export default memo(Root);
