import { LoadingOutlined } from '@ant-design/icons';
import { Form, FormInstance, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { API, getError } from '../../util';

type IProps = {
  form: FormInstance;
  brands: { id: number; name: string }[];
  nameBrand?: string;
  labelBrand?: string;
  placeholderBrand?: string;
  nameModel?: string;
  labelModel?: string;
  placeholderModel?: string;
  required?: boolean;
  requiredBrandMessage?: string;
  requiredModelMessage?: string;
  bordered?: boolean;
};

const VehicleSelector = (props: IProps) => {
  const {
    form,
    brands,
    nameBrand = "vehicle_brand_id",
    labelBrand = "Marca del vehículo",
    placeholderBrand = "Seleccionar marca del vehículo",
    nameModel = "vehicle_model_id",
    labelModel = "Modelo",
    placeholderModel = "Seleccionar modelo del vehículo",
    required = false,
    requiredBrandMessage = "Marca del vehículo obligatoria",
    requiredModelMessage = "Modelo del vehículo obligatorio",
    bordered = true,
  } = props;

  const [loadingModels, setLoadingModels] = useState(false);
  const [models, setModels] = useState([]);

  const fetchModels = useCallback(
    async (brandId, clear = false) => {
      if (clear) {
        const values = form.getFieldsValue();
        form.setFieldsValue({
          ...values,
          [nameModel]: null,
        });
      }
      setLoadingModels(true);
      try {
        const response: any = await API.get("resources/vehicle-models", {
          params: { brand: brandId },
        });
        if (!Array.isArray(response?.data)) {
          throw new Error("Error al obtener datos.");
        }
        setModels(response.data);
      } catch (error) {
        message.error(getError(error));
      }
      setLoadingModels(false);
    },
    [form, nameModel]
  );

  useEffect(() => {
    const brandId = form.getFieldValue(nameBrand);
    if (brandId) {
      fetchModels(brandId);
    }
  }, [form, fetchModels, nameBrand]);

  return (
    <>
      <Form.Item
        name={nameBrand}
        label={labelBrand}
        rules={[
          {
            required: required,
            message: requiredBrandMessage,
          },
        ]}
      >
        <select
          onChange={(event) => fetchModels(event?.target?.value, true)}
          className={`ant-input gx-w-100 ${
            bordered ? "" : "ant-input-borderless gx-input-underlined"
          }`}
        >
          <option hidden>{placeholderBrand}</option>
          {brands.map((type: any, index: number) => (
            <option key={`${index}`} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </Form.Item>

      <Form.Item
        name={nameModel}
        label={labelModel}
        rules={[
          {
            required: required,
            message: requiredModelMessage,
          },
        ]}
      >
        {loadingModels ? (
          <div className="gx-text-center gx-py-3 gx-text-grey">
            <LoadingOutlined /> Cargando modelos
          </div>
        ) : (
          <select
            placeholder={placeholderModel}
            className={`ant-input gx-w-100 ${
              bordered ? "" : "ant-input-borderless gx-input-underlined"
            }`}
          >
            <option hidden>{placeholderModel}</option>
            {models.map((model: any) => (
              <option key={`model${model.id}`} value={model.id}>
                {model.name}
              </option>
            ))}
          </select>
        )}
      </Form.Item>
    </>
  );
};

export default VehicleSelector;
