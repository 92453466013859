import React from "react";

type IProps = {
  children: React.ReactNode;
};

const AppModule = (props: IProps) => {
  const { children } = props;

  return (
    <div className="gx-app-module gx-px-4 gx-pb-4">
      <div className="gx-w-100">{children}</div>
    </div>
  );
};

export default AppModule;
