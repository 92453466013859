import React from "react";
import { Route, Switch } from "react-router-dom";

import AsyncComponent from "../util/AsyncComponent";

type IProps = { match: any };

const App = (props: IProps) => {
  const { match } = props;

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={AsyncComponent(() => import("./Dashboard"))}
        />
        <Route
          path={`${match.url}shipment`}
          component={AsyncComponent(() => import("./Shipment"))}
        />
        <Route
          path={`${match.url}trip`}
          component={AsyncComponent(() => import("./Trip"))}
        />
        <Route
          path={`${match.url}profile`}
          component={AsyncComponent(() => import("./Profile"))}
        />
        <Route
          path={`${match.url}driver`}
          component={AsyncComponent(() => import("./Driver"))}
        />
        <Route
          path={`${match.url}helpdesk`}
          component={AsyncComponent(() => import("./HelpDesk"))}
        />
        <Route
          path={`${match.url}page`}
          component={AsyncComponent(() => import("./Page"))}
        />
        <Route
          path="*"
          component={AsyncComponent(
            () => import("../containers/Errors/Error404")
          )}
        />
      </Switch>
    </div>
  );
};

export default App;
