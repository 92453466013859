import { CheckOutlined } from "@ant-design/icons";
import URLSearchParams from "@ungap/url-search-params";
import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";

import { userReset } from "../../redux/actions/Auth";
import { InfoView } from "../../components";
import { PublicContainer } from "./components";

type IProps = {} & RouteComponentProps;

const Reset = (props: IProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userEmail = params.get("email");
  const resetToken = params.get("token");

  const onFinish = (values: any) => {
    dispatch(userReset(values));
  };

  useEffect(() => {
    if (token !== null) {
      setTimeout(() => {
        history.push("/dashboard/shipments");
      }, 100);
    }
  }, [token, history]);

  return (
    <PublicContainer title="Restablece tu contraseña">
      <InfoView label="Procesando" errorTitle="Error al procesar">
        <Form
          initialValues={{}}
          name="resetForm"
          onFinish={onFinish}
          requiredMark={false}
          layout="vertical"
          className="gx-login-form"
        >
          <div className="gx-text-grey gx-mb-4">
            Introduce el código de verificación que enviamos a tu email y la
            nueva contraseña.
          </div>

          <Form.Item
            label="Código de verificación"
            name="token"
            rules={[{ required: true, message: "El código es obligatorio" }]}
            hasFeedback
            colon={false}
            initialValue={resetToken}
          >
            <Input
              placeholder="Código de verificación"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Form.Item
            label="Email de tu cuenta"
            name="email"
            rules={[
              { required: true, message: "El email es obligatorio" },
              { type: "email", message: "No es un email válido" },
            ]}
            hasFeedback
            colon={false}
            initialValue={userEmail}
          >
            <Input
              type="email"
              placeholder="Email de tu cuenta"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Form.Item
            label="Nueva contraseña"
            name="password"
            rules={[
              {
                required: true,
                message: "Escribe la nueva contraseña",
              },
              {
                min: 6,
                message: "Debe contener al menos 6 caracteres",
              },
            ]}
            hasFeedback
            colon={false}
          >
            <Input
              type="password"
              placeholder="Nueva contraseña"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Form.Item
            label="Confirma la nueva contraseña"
            name="password_confirmation"
            dependencies={["password"]}
            hasFeedback
            colon={false}
            rules={[
              {
                required: true,
                message: "Confirma la nueva contraseña",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "La contraseña y la confirmación no coinciden"
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder="Confirma la nueva contraseña"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Button
            type="primary"
            icon={<CheckOutlined />}
            className="gx-my-4"
            htmlType="submit"
            block
            size="large"
          >
            Cambiar contraseña
          </Button>

          <div className="gx-py-2 gx-text-center">
            <Link to="/login" className="gx-text-primary">
              Iniciar sesión
            </Link>
          </div>
        </Form>
      </InfoView>
    </PublicContainer>
  );
};

export default Reset;
