import { Col, Image, Layout, Row } from "antd";
import React, { ReactNode } from "react";

import { Moment } from "../../../util";

type IProps = {
  title?: string;
  subtitle?: string;
  children: ReactNode;
};

const PublicContainer = (props: IProps) => {
  const { title, subtitle, children } = props;

  return (
    <Layout style={{ height: "100vh", overflow: "auto" }}>
      <Layout.Content>
        <Row
          align="middle"
          className="gx-mx-0"
          gutter={0}
          style={{ height: "100vh" }}
        >
          <Col span={24}>
            <Row gutter={0} className="gx-mx-0">
              <Col className="gx-d-flex gx-flex-1 gx-justify-content-center gx-py-4">
                <Image
                  preview={false}
                  width={200}
                  src={require("assets/images/logo-green.png")}
                />
              </Col>
            </Row>
            {title || subtitle ? (
              <Row className="gx-mx-0 gx-py-5">
                <Col
                  span={24}
                  className="gx-text-center gx-font-weight-light gx-text-grey"
                >
                  {title ? (
                    <div className="gx-fs-xxxl gx-font-weight-normal gx-mb-2">
                      {title}
                    </div>
                  ) : null}
                  {subtitle ? <div className="gx-fs-lg">{subtitle}</div> : null}
                </Col>
              </Row>
            ) : null}
            <Row className="gx-mx-0 gx-pb-5">
              <Col span={24} className="gx-px-0">
                {children}
                <div className="gx-text-center gx-fs-sm gx-text-light gx-bg-transparent gx-border-0">
                  &copy;{Moment().format("YYYY")} | YOLO.mx &reg;
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PublicContainer;
