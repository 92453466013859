import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, FormInstance, Radio } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import React, { useCallback, useEffect, useState } from "react";

type IProps = {
  form: FormInstance;
  field: FormListFieldData;
  fieldsCount: number;
  remove: (index: number | number[]) => void;
  name: string | number | React.ReactText[];
  routes: any[];
  bordered?: boolean;
};

const RouteListItem = (props: IProps) => {
  const {
    form,
    field,
    fieldsCount,
    remove,
    name,
    routes,
    bordered = false,
  } = props;

  const [destinations, setDestinations] = useState([]);

  const onChangeOrigin = useCallback(
    (city: string) => {
      const dest = routes.find((item: any) => item.name === city);
      setDestinations(dest.destinations);
    },
    [routes]
  );

  useEffect(() => {
    const item = form.getFieldValue(name);
    const origin = item[field?.key];
    if (origin?.origin) {
      onChangeOrigin(origin?.origin);
    }
  }, [field, form, name, onChangeOrigin]);

  return (
    <Form.Item
      label={"¿Qué rutas cubres y con qué frecuencia?"}
      required={false}
      className={
        fieldsCount > 1
          ? "gx-border gx-border-light gx-px-2 gx-position-relative gx-pt-4"
          : ""
      }
    >
      {fieldsCount > 1 ? (
        <MinusCircleOutlined
          onClick={() => remove(field.name)}
          style={{
            position: "absolute",
            right: "4px",
            top: "-22px",
          }}
        />
      ) : null}

      <Form.Item
        name={[field.name, "origin"]}
        fieldKey={[field.fieldKey, "origin"]}
        key="origin"
      >
        <select
          onChange={(event) => onChangeOrigin(event?.target?.value)}
          className={`ant-input gx-w-100 ${
            bordered ? "" : "ant-input-borderless gx-input-underlined"
          }`}
        >
          <option hidden>Seleciona una ciudad de origen</option>
          {routes?.map((area: any, index: number) => (
            <option key={`originCity${index}`} value={area.name}>
              {area.name}
            </option>
          ))}
        </select>
      </Form.Item>

      <Form.Item
        name={[field.name, "destination"]}
        fieldKey={[field.fieldKey, "destination"]}
        key="destination"
      >
        <select
          className={`ant-input gx-w-100 ${
            bordered ? "" : "ant-input-borderless gx-input-underlined"
          }`}
        >
          <option hidden>Seleciona una ciudad de destino</option>
          {destinations?.map((area: any, index: number) => (
            <option key={`destinationCity${index}`} value={area.name}>
              {area.name}
            </option>
          ))}
        </select>
      </Form.Item>

      <Form.Item
        name={[field.name, "frequency"]}
        fieldKey={[field.fieldKey, "frequency"]}
        key="frequency"
      >
        <Radio.Group>
          <Radio value="DAILY">Diario</Radio>
          <Radio value="TIMESWEEKLY">2 a 3 veces por semana</Radio>
          <Radio value="WEEKLY">Semanal</Radio>
          <Radio value="MONTHLY">Mensual</Radio>
          <Radio value="OTHER">Otra</Radio>
        </Radio.Group>
      </Form.Item>
    </Form.Item>
  );
};

export default RouteListItem;
