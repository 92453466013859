import { Form, Input, Modal } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

type IProps = {
  label: string;
  placeholder?: string;
  nameAddress: string;
  nameCity: string;
  nameLatitude: string;
  nameLongitude: string;
  form: FormInstance<any>;
  required?: boolean;
  isFromList?: any;
  listName?: string;
  bordered?: boolean;
  initialAddress?: string;
  routes?: any[];
  cities?: any[];
  type?: "origin" | "destination" | "stop";
  originCity?: string;
};

const SearchLocationInput = (props: IProps) => {
  const {
    label,
    placeholder,
    nameAddress,
    nameCity,
    nameLatitude,
    nameLongitude,
    form,
    required = false,
    isFromList,
    listName,
    bordered = false,
    initialAddress,
    routes,
    cities,
    type,
    originCity,
  } = props;

  const matchCityWithArea = (city: string) => {
    if (!type) {
      return true;
    }
    city = city ? city.trim().toUpperCase() : city;
    let match: string;
    if (type === "origin") {
      match = cities.find(
        (item: any) => item.city.trim().toUpperCase() === city
      );
    } else if (type === "destination") {
      const matchCity = cities.find(
        (item: any) => item.city.trim().toUpperCase() === city
      );
      const formValues = form.getFieldsValue();
      let origin = formValues[originCity];
      origin = (origin ? origin.trim().toUpperCase() : "").split(",");
      const originCitie = cities.find(
        (item: any) => item.city.trim().toUpperCase() === origin[0]
      );
      const area = routes.find(
        (item: any) => item.id === originCitie?.area?.id
      );
      const destinations = area?.destinations ? area.destinations : [];
      match = destinations.find((item: any) => item.id === matchCity?.area?.id);
    }
    return !!match;
  };

  const onChange = async ({ label, value }) => {
    const results = await geocodeByAddress(label);
    const { lat, lng } = await getLatLng(results[0]);
    const terms = value.terms.slice(Math.max(value.terms.length - 3, 0));
    const city = [terms[0].value, terms[1].value].join(", ");
    const valid = matchCityWithArea(terms[0].value);
    let formValues = form.getFieldsValue();
    let values: any;
    if (!valid) {
      const origin = formValues[originCity];
      const title =
        type === "destination" && origin
          ? `Sin cobertura en la ruta`
          : type === "destination" && !origin
          ? `No haz elegido un origen`
          : `Sin cobertura en ${city}`;
      const content =
        type === "destination" && origin
          ? `La ruta de ${origin} a ${city} no la cubrimos actualmente, estamos trabajando para tener más ciudades muy pronto.`
          : type === "destination" && !origin
          ? `Primero elige una dirección de origen para poder elegir un destino`
          : `Por el momento no tenemos cobertura en ${city}, estamos trabajando para tener más ciudades muy pronto.`;
      Modal.confirm({
        type: "warn",
        title,
        content,
      });
      values = {
        [nameAddress]: null,
        [nameLatitude]: null,
        [nameLongitude]: null,
        [nameCity]: null,
      };
    } else {
      values = {
        [nameAddress]: label,
        [nameLatitude]: lat,
        [nameLongitude]: lng,
        [nameCity]: city,
      };
    }
    if (isFromList) {
      formValues[listName] = Object.values({
        ...formValues[listName],
        [isFromList.name]: values,
      });
    } else {
      formValues = { ...formValues, ...values };
    }
    form.setFieldsValue(formValues);
  };

  return (
    <>
      <Form.Item
        name={isFromList ? [isFromList.name, nameCity] : nameCity}
        {...(isFromList ? { fieldKey: [isFromList.fieldKey, nameCity] } : {})}
        noStyle
      >
        <Input className="gx-d-none" />
      </Form.Item>

      <Form.Item
        name={isFromList ? [isFromList.name, nameLatitude] : nameLatitude}
        {...(isFromList
          ? { fieldKey: [isFromList.fieldKey, nameLatitude] }
          : {})}
        noStyle
      >
        <Input className="gx-d-none" />
      </Form.Item>

      <Form.Item
        name={isFromList ? [isFromList.name, nameLongitude] : nameLongitude}
        {...(isFromList
          ? { fieldKey: [isFromList.fieldKey, nameLongitude] }
          : {})}
        noStyle
      >
        <Input className="gx-d-none" />
      </Form.Item>

      <Form.Item
        label={label}
        name={isFromList ? [isFromList.name, nameAddress] : nameAddress}
        {...(isFromList
          ? { fieldKey: [isFromList.fieldKey, nameAddress] }
          : {})}
        rules={[
          {
            required: required,
            message: "Ingresa una dirección válida",
          },
        ]}
        hasFeedback
        colon={false}
        initialValue={null}
      >
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["mx"],
            },
            types: ["address"],
          }}
          minLengthAutocomplete={4}
          selectProps={{
            defaultInputValue: initialAddress,
            placeholder: placeholder ? placeholder : label,
            noOptionsMessage: ({ inputValue }) => (
              <span>
                Escribe una dirección para
                <br />
                mostrar resultados
              </span>
            ),
            onChange: onChange,
            styles: {
              control: (provided: any) => ({
                ...provided,
                ...(bordered
                  ? {}
                  : {
                      border: "none",
                      borderBottom: "solid 2px #e8e8e8",
                      boxShadow: "none",
                      borderRadius: "none",
                    }),
              }),
              indicatorSeparator: (provided: any) => ({
                ...provided,
                backgroundColor: "transparent",
              }),
              placeholder: (provided: any) => ({
                ...provided,
                color: "#bfbfbf",
              }),
              valueContainer: (provided: any) => ({
                ...provided,
                paddingLeft: 0,
                ...(bordered
                  ? { paddingLeft: "10px" }
                  : {
                      paddingLeft: 0,
                    }),
              }),
              dropdownIndicator: (provided: any) => ({
                ...provided,
                paddingLeft: 0,
              }),
            },
          }}
        />
      </Form.Item>
    </>
  );
};

export default SearchLocationInput;
