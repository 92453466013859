import { CheckOutlined } from "@ant-design/icons";
import { Button, Form, message, Result, Row } from "antd";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";

import {
  InfoView,
  SearchLocationInput,
  SizesSelector,
  SkeletonLoading,
} from "../../components";
import { API, getError } from "../../util";
import { PublicContainer } from "./components";

type IProps = {} & RouteComponentProps;

const Quote = (props: IProps) => {
  const { history } = props;

  const [result, setResult] = useState<any>();
  const [resources, setResources] = useState({
    vehicle_types: [],
    states: [],
    brands: [],
    platforms: [],
    sizes: [],
    insurance_companies: [],
    routes: [],
    cities: [],
  });
  const [loading, setLoading] = useState(true);

  const token = useSelector((state: any) => state.auth.token);
  const [quoteShipmetForm] = Form.useForm();

  useEffect(() => {
    if (token !== null) {
      setTimeout(() => {
        history.push("/dashboard/shipments");
      }, 100);
    }
  }, [token, history]);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    setLoading(true);
    try {
      const response: any = await API.get("resources");
      if (!response?.data?.sizes) {
        throw new Error("Error al obtener recursos.");
      }
      setResources(response.data);
    } catch (err) {
      message.error(getError(err));
    }
    setLoading(false);
  };

  const onFinish = (values: any) => {
    switch (values.size_id) {
      case 1:
        setResult(115);
        break;
      case 2:
        setResult(185);
        break;
      case 3:
        setResult(249);
        break;
      case 4:
        setResult(490);
        break;
      default:
        setResult(0);
        break;
    }
  };

  return (
    <PublicContainer title="Cotiza tu envío">
      <SkeletonLoading loading={loading}>
        {result ? (
          <Result
            status="success"
            title={
              <>
                El precio aproximado de tu envío es de{" "}
                <span className="gx-font-weight-semi-bold">
                  <NumberFormat
                    value={result}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
              </>
            }
            extra={[
              <Button
                type="primary"
                block
                key="register"
                onClick={() => history.push("/register")}
              >
                Regístrate para enviar
              </Button>,
              <Button
                type="primary"
                block
                key="login"
                onClick={() => history.push("/login")}
              >
                Iniciar sesión
              </Button>,
              <Button
                type="default"
                block
                key="return"
                onClick={() => setResult(null)}
              >
                Cotizar otra ruta
              </Button>,
            ]}
          />
        ) : (
          <Form
            form={quoteShipmetForm}
            initialValues={{}}
            name="quoteShipmetForm"
            onFinish={onFinish}
            requiredMark={false}
            layout="vertical"
            className="gx-login-form"
          >
            <Row className="gx-mx-0 gx-pb-5">
              <InfoView
                label="Cotizando envío"
                errorTitle="Error al cotizar envío"
              >
                <SearchLocationInput
                  form={quoteShipmetForm}
                  label="Dirección de origen"
                  placeholder="Selecciona origen"
                  nameAddress="start_address"
                  nameCity="start_city"
                  nameLatitude="start_latitude"
                  nameLongitude="start_longitude"
                  required={true}
                  routes={resources.routes}
                  cities={resources.cities}
                  type="origin"
                />

                <SearchLocationInput
                  form={quoteShipmetForm}
                  label="Dirección de destino"
                  placeholder="Selecciona destino"
                  nameAddress="end_address"
                  nameCity="end_city"
                  nameLatitude="end_latitude"
                  nameLongitude="end_longitude"
                  required={true}
                  routes={resources.routes}
                  cities={resources.cities}
                  type="destination"
                  originCity="start_city"
                />

                <SizesSelector
                  name="size_id"
                  label="¿De que tamaño es tu envío?"
                  sizes={resources?.sizes ? resources.sizes : []}
                />
              </InfoView>

              <Button
                type="primary"
                icon={<CheckOutlined />}
                className="gx-my-4"
                htmlType="submit"
                block
                size="large"
              >
                Cotizar
              </Button>

              {/* {cost} */}

              <div className="gx-py-2 gx-text-center">
                ¿Ya tienes cuenta?{" "}
                <Link to="/login" className="gx-text-primary">
                  Inicia sesión
                </Link>
              </div>
            </Row>
          </Form>
        )}
      </SkeletonLoading>
    </PublicContainer>
  );
};

export default Quote;
