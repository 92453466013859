import {
  CheckOutlined,
  ExclamationCircleOutlined,
  MobileOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Input, message, Modal, Spin } from "antd";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

import { API, getError } from "../../../util";

type IProps = {
  phone?: any;
  userId: number;
  onVerify: () => void;
};

const VerifyPhoneForm = (props: IProps) => {
  const { phone, userId, onVerify = () => {} } = props;

  const [submitting, setSubmitting] = useState(false);

  const openConfirmResendCode = () => {
    Modal.confirm({
      title: "Volver a enviar código por SMS",
      icon: <ExclamationCircleOutlined />,
      content: "Recibirás un código de 4 dígitos que debes introducir aquí",
      okText: (
        <>
          <SendOutlined /> Enviar
        </>
      ),
      async onOk() {
        try {
          const response: any = await API.post(`verify/send-phone`, {
            user: userId,
          });
          if (!response?.data) {
            throw new Error("Error al enviar código.");
          }
          message.info("Se ha enviado el código por SMS");
        } catch (error) {
          message.error(getError(error));
        }
      },
    });
  };

  const submitForm = async (values: any) => {
    setSubmitting(true);
    try {
      const response = await API.post("verify/phone", {
        ...values,
        user: userId,
      });
      if (!response?.data) {
        throw new Error("Error al verificar el teléfono móvil.");
      }
      message.info("Se ha verificado el teléfono móvil con éxito");
      onVerify();
    } catch (error) {
      message.error(getError(error));
      setSubmitting(false);
    }
  };

  return (
    <div className="gx-my-4">
      <Card size="small" className="gx-text-center gx-py-1 gx-bg-light-grey">
        <Spin spinning={submitting} tip="Verificando">
          <h2 className="gx-text-primary">
            <MobileOutlined /> Verifica tu teléfono móvil
          </h2>
          <h4>
            {phone ? (
              <>
                Introduce el código que hemos enviado al número{" "}
                <NumberFormat
                  displayType={"text"}
                  value={phone}
                  format="### ### #### ####"
                />{" "}
              </>
            ) : (
              <>Introduce el código que enviamos a tu teléfono móvil por SMS</>
            )}
          </h4>
          <Form
            className="gx-m-4"
            name="verifyPhoneForm"
            initialValues={{ code: null }}
            onFinish={submitForm}
          >
            <Form.Item
              name="code"
              rules={[{ required: true, message: "Introduce el código" }]}
              hasFeedback
            >
              <Input
                size="large"
                maxLength={4}
                minLength={4}
                autoComplete="off"
                style={{ width: "200px" }}
                className="gx-text-center"
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "200px" }}>
              <CheckOutlined /> Verificar
            </Button>
          </Form>
          <div className="gx-text-grey gx-fs-sm gx-font-weight-light gx-my-3">
            Si no haz recibido el código, espera unos minutos.
          </div>
          <Button type="link" onClick={openConfirmResendCode}>
            Volver a enviar código
          </Button>
        </Spin>
      </Card>
    </div>
  );
};

export default VerifyPhoneForm;
