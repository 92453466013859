import { Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";

type IProps = {
  profile: any;
};

const UnverifiedAccount = (props: IProps) => {
  const { profile } = props;

  return !profile?.mobile_phone_verified ? (
    <Card className="gx-w-100 gx-bg-amber-light" bordered={false} size="small">
      <h4 className="gx-text-center">Tu cuenta necesita verificación</h4>
      <Link to="/profile/verify" className="gx-btn gx-btn-primary gx-btn-block">
        Verificar ahora
      </Link>
    </Card>
  ) : (
    <></>
  );
};

export default UnverifiedAccount;
