import { CheckOutlined, CodeSandboxOutlined } from "@ant-design/icons";
import { Avatar, Button, Checkbox, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";

import { userLogin, userRelogin } from "../../redux/actions/Auth";
import { InfoView } from "../../components";
import { PublicContainer } from "./components";

type IProps = {} & RouteComponentProps;

const Login = (props: IProps) => {
  const { history } = props;

  const dispatch = useDispatch();
  const { token, authUserRemember, tokenRemember } = useSelector((state: any) => state.auth);

  const onFinish = (values: any) => {
    dispatch(userLogin(values));
  };

  const relogin = () => {
    dispatch(userRelogin(tokenRemember, authUserRemember));
  };

  useEffect(() => {
    if (token !== null) {
      setTimeout(() => {
        history.push("/dashboard/shipments");
      }, 100);
    }
  }, [token, history]);

  return (
    <PublicContainer title="" subtitle="Inicia sesión">
      {authUserRemember?.id ? (
        <div className="gx-text-center gx-mb-4 gx-pb-4">
          <Button type="text" onClick={relogin} style={{ height: "auto" }}>
            <Avatar
              src={
                authUserRemember?.photo_thumb_url
                  ? authUserRemember.photo_thumb_url
                  : require("assets/images/avatar.svg")
              }
              size={96}
              shape="square"
              className="gx-mx-auto"
            />
            <div className="gx-text-light">{authUserRemember?.name}</div>
          </Button>
        </div>
      ) : null}
      <InfoView label="Iniciando sesión" errorTitle="Error al iniciar sesión">
        <Form
          initialValues={{ remember: true }}
          name="loginForm"
          onFinish={onFinish}
          requiredMark={false}
          layout="vertical"
          className="gx-login-form"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Email obligatorio" }]}
            hasFeedback
            colon={false}
            initialValue={null}
          >
            <Input
              type="email"
              placeholder="Tu email"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: "Contraseña obligatoria" }]}
            hasFeedback
            colon={false}
          >
            <Input.Password
              placeholder="Tu contraseña"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Recordar contraseña</Checkbox>
          </Form.Item>

          <div className="gx-my-2 gx-text-right">
            <Link to="/recovery" className="gx-text-grey">
              ¿Olvidaste tu contraseña?
            </Link>
          </div>

          <Button
            type="primary"
            icon={<CheckOutlined />}
            className="gx-mb-0 gx-my-4"
            htmlType="submit"
            block
            size="large"
          >
            Acceder
          </Button>

          <div className="gx-mb-4 gx-text-center">
            ¿No tienes cuenta?{" "}
            <Link to="/register" className="gx-text-primary">
              Regístrate
            </Link>
          </div>

          <Link to="/register?driver=1" className="gx-btn gx-btn-block gx-my-2">
            Conviértete en conductor (Yolo)
          </Link>

          <Link to="/quote" className="gx-btn gx-btn-block gx-my-4">
            <CodeSandboxOutlined /> Cotiza tu envío
          </Link>
        </Form>
      </InfoView>
    </PublicContainer>
  );
};

export default Login;
