import { SendOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";

import { userRecovery } from "../../redux/actions/Auth";
import { InfoView } from "../../components";
import { PublicContainer } from "./components";

type IProps = {} & RouteComponentProps;

const Recovery = (props: IProps) => {
  const { history } = props;

  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);

  const onFinish = (values: any) => {
    dispatch(userRecovery(values));
  };

  useEffect(() => {
    if (token !== null) {
      setTimeout(() => {
        history.push("/dashboard/shipments");
      }, 100);
    }
  }, [token, history]);

  return (
    <PublicContainer title="Recupera tu contraseña">
      <InfoView label="Procesando" errorTitle="Error al procesar">
        <Form
          initialValues={{}}
          name="recoveryForm"
          onFinish={onFinish}
          requiredMark={false}
          layout="vertical"
          className="gx-login-form"
        >
          <div className="gx-text-grey gx-mb-4">
            Introduce el correo electrónico de tu cuenta para recuperar tu
            contraseña.
          </div>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "El email es obligatorio" },
              { type: "email", message: "No es un email válido" },
            ]}
            hasFeedback
            colon={false}
            initialValue={null}
          >
            <Input
              type="email"
              placeholder="Email de tu cuenta"
              bordered={false}
              className="gx-input-underlined"
            />
          </Form.Item>

          <Button
            type="primary"
            icon={<SendOutlined />}
            className="gx-my-4"
            htmlType="submit"
            block
            size="large"
          >
            Recuperar la contraseña
          </Button>

          <div className="gx-py-2 gx-text-center">
            <Link to="/login" className="gx-text-primary">
              Iniciar sesión
            </Link>
          </div>
        </Form>
      </InfoView>
    </PublicContainer>
  );
};

export default Recovery;
