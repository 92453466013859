import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from "../../../redux/actions/Setting";
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../../constants/ThemeSetting";

const SidebarLogo = () => {
  const dispatch = useDispatch();
  const { width, navCollapsed } = useSelector((state: any) => state.settings);
  const navStyle = useSelector((state: any) => state.settings.navStyle);

  const onPressLineBar = () => {
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    } else if (navStyle === NAV_STYLE_FIXED) {
      dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
    } else {
      dispatch(onNavStyleChange(NAV_STYLE_FIXED));
    }
  };

  return (
    <div className="gx-layout-sider-header">
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              navStyle === NAV_STYLE_MINI_SIDEBAR ? "menu-unfold" : "menu-fold"
            }`}
            onClick={onPressLineBar}
          />
        </div>
      ) : null}

      <Link to="/dashboard/shipments" className="gx-site-logo">
        <img alt="" src={require("assets/images/logo.png")} />
      </Link>
    </div>
  );
};

export default SidebarLogo;
