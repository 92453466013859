import { CheckCircleOutlined } from "@ant-design/icons";
import { Badge, Card } from "antd";
import React from "react";

type IProps = {
  value: React.ReactNode | string;
};

const Verified = (props: IProps) => {
  const { value } = props;

  return (
    <div className="gx-my-3">
      <Badge.Ribbon
        color="green"
        text={
          <>
            Verificado <CheckCircleOutlined />
          </>
        }
        placement="end"
      >
        <Card size="small">
          <div className="gx-fs-xl gx-text-left gx-font-weight-light">
            {value}
          </div>
        </Card>
      </Badge.Ribbon>
    </div>
  );
};

export default Verified;
