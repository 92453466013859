import { Drawer, Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleRightDrawer } from "../../../redux/actions/Setting";
import RightSidebarContent from "./RightSidebarContent";

const RightSidebar = () => {
  const dispatch = useDispatch();

  const { rightDrawerOpened } = useSelector((state: any) => state.settings);

  const onToggleCollapsedNav = () => {
    dispatch(toggleRightDrawer(!rightDrawerOpened));
  };

  return (
    <Layout.Sider
      className={`gx-app-sidebar gx-collapsed-sidebar`}
      trigger={null}
      theme="dark"
    >
      <Drawer
        className="secondary-drawer-right"
        placement="right"
        closable={false}
        onClose={onToggleCollapsedNav}
        visible={rightDrawerOpened}
      >
        <RightSidebarContent />
      </Drawer>
    </Layout.Sider>
  );
};
export default RightSidebar;
