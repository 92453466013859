import { MenuOutlined } from "@ant-design/icons";
import { Grid, Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { toggleRightDrawer } from "../../../redux/actions";

const { Header } = Layout;

const Topbar = () => {
  const { rightDrawerOpened } = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  return (
    <Header>
      {!screens.lg ? (
        <Link to="/dashboard/shipments" className="gx-d-block">
          <img
            alt=""
            src={require("assets/images/logo-green.png")}
            style={{ height: "32px" }}
          />
        </Link>
      ) : null}
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-user-nav">
          <div className="gx-linebar gx-ml-3">
            <MenuOutlined
              onClick={() => {
                dispatch(toggleRightDrawer(!rightDrawerOpened));
              }}
              style={{ color: "#238736" }}
            />
          </div>
        </li>
      </ul>
    </Header>
  );
};

export default Topbar;
