import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Avatar, List, Typography } from "antd";
import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import { SkeletonLoading } from "../../../components";

type IProps = {
  profile: any;
  loading: boolean;
  size?: "small" | "large";
  editLinks?: boolean;
};

const ProfileHeader = (props: IProps) => {
  const { profile, loading, size = "large", editLinks = false } = props;

  const avatar = (
    <Avatar
      src={
        profile?.photo_thumb_url
          ? profile?.photo_thumb_url
          : require("assets/images/avatar.svg")
      }
      size={size === "small" ? 45 : 80}
    />
  );

  return (
    <SkeletonLoading loading={loading} rows={3} avatar={{ size: 45 }}>
      <List
        className="gx-w-100"
        dataSource={[1]}
        renderItem={(item: any, index: number) => (
          <List.Item key="profileData">
            <List.Item.Meta
              avatar={
                editLinks ? (
                  <Link
                    to="/profile/edit-photo"
                    className="gx-d-block gx-text-center gx-mr-3"
                  >
                    {avatar}
                    <span className="gx-d-block gx-mt-1 gx-fs-sm gx-font-weight-light">
                      Cambiar foto
                    </span>
                  </Link>
                ) : (
                  avatar
                )
              }
              title={
                <Typography.Text
                  ellipsis={true}
                  className={`gx-fs-lg ${size === "small" ? "gx-mt-2" : null}`}
                  style={{ lineHeight: 2 }}
                >
                  <Link to="/profile/me" className="gx-text-dark">
                    {profile?.full_name}
                  </Link>
                </Typography.Text>
              }
              description={
                size === "small" ? (
                  editLinks && (
                    <span className="gx-d-block gx-mt-2 gx-font-weight-light">
                      <Link
                        to="/profile/change-password"
                        className="gx-d-block gx-mt-2 gx-fs-sm"
                      >
                        Cambiar contraseña
                      </Link>
                    </span>
                  )
                ) : (
                  <span className="gx-d-block gx-mt-2 gx-font-weight-light">
                    <Typography.Text ellipsis={true} className="gx-text-light">
                      {profile?.mobile_phone_verified ? (
                        <CheckCircleFilled className="gx-text-primary" />
                      ) : (
                        <ExclamationCircleFilled className="gx-text-warning" />
                      )}{" "}
                      <NumberFormat
                        displayType={"text"}
                        value={profile?.mobile_phone}
                        format="### ### #### ####"
                      />
                    </Typography.Text>
                    <br />
                    <Typography.Text ellipsis={true} className="gx-text-light">
                      {/* {profile?.email_verified ? (
                        <CheckCircleFilled className="gx-text-primary" />
                      ) : (
                        <ExclamationCircleFilled className="gx-text-warning" />
                      )}{" "} */}
                      {profile?.email}
                    </Typography.Text>
                    {editLinks ? (
                      <div className="gx-flex-row gx-justify-content-between">
                        <Link
                          to="/profile/edit"
                          className="gx-flex-column gx-mt-2 gx-fs-sm"
                        >
                          Editar información
                        </Link>
                        <Link
                          to="/profile/change-password"
                          className="gx-flex-column gx-mt-2 gx-fs-sm"
                        >
                          Contraseña
                        </Link>
                      </div>
                    ) : null}
                  </span>
                )
              }
            />
          </List.Item>
        )}
      />
    </SkeletonLoading>
  );
};

export default ProfileHeader;
