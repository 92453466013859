import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import vhCheck from "vh-check";

import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";

// Read more: https://github.com/Hiswe/vh-check
vhCheck();

// Add this import:
// Wrap the rendering in a function:
const render = (Component: any) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => render(NextApp));
}
