import {
  CarOutlined,
  CommentOutlined,
  DashboardOutlined,
  DropboxOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Grid, Layout, Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Tabbar = () => {
  const { authUser } = useSelector((state: any) => state.auth);

  const screens = Grid.useBreakpoint();

  return !screens.lg ? (
    <Layout.Footer className="main-tabbar gx-nav-header">
      <div className="main-tabbar-content">
        <Menu mode="horizontal" overflowedIndicator={<MenuOutlined />}>
          <Menu.Item
            key="track"
            icon={
              <Link
                to="/dashboard/shipments"
                className="gx-d-block gx-text-center"
              >
                <DashboardOutlined className="tabbar-icon" />
                <span className="tabbar-label">Track</span>
              </Link>
            }
          ></Menu.Item>
          <Menu.Item
            key="publish"
            icon={
              <Link
                to={
                  authUser?.is_driver ? `/trip/publish` : "/trip/invitation"
                }
                className="gx-d-block gx-text-center"
              >
                <CarOutlined className="tabbar-icon" />
                <span className="tabbar-label">Publicar</span>
              </Link>
            }
          />
          <Menu.Item
            key="send"
            icon={
              <Link to="/shipment/start" className="gx-d-block gx-text-center">
                <DropboxOutlined className="tabbar-icon" />
                <span className="tabbar-label">Enviar</span>
              </Link>
            }
          />
          <Menu.Item
            key="helpdesk"
            icon={
              <Link to="/helpdesk/list" className="gx-d-block gx-text-center">
                <CommentOutlined className="tabbar-icon" />
                <span className="tabbar-label">Chat</span>
              </Link>
            }
          />
          <Menu.Item
            key="profile/me"
            icon={
              <Link to="/profile/me" className="gx-d-block gx-text-center">
                <UserOutlined className="tabbar-icon" />
                <span className="tabbar-label">Perfil</span>
              </Link>
            }
          />
        </Menu>
      </div>
    </Layout.Footer>
  ) : (
    <></>
  );
};

export default Tabbar;
