import URLSearchParams from "@ungap/url-search-params";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";

import { VerifyPhoneForm } from "../Profile/components";
import { PublicContainer } from "./components";

type IProps = {} & RouteComponentProps;

const VerifyPhone = (props: IProps) => {
  const { history } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = parseInt(params.get("id"));
  const token = useSelector((state: any) => state.auth.token);

  useEffect(() => {
    if (!Boolean(userId)) {
      setTimeout(() => {
        history.push("/login");
      }, 100);
    }
  }, [userId, history]);

  const onVerify = () => {
    setTimeout(() => {
      history.push("/login");
    }, 100);
  };

  useEffect(() => {
    if (token !== null) {
      setTimeout(() => {
        history.push("/dashboard/shipments");
      }, 100);
    }
  }, [token, history]);

  return (
    <PublicContainer>
      <VerifyPhoneForm userId={userId} onVerify={onVerify} />
      <div className="gx-py-2 gx-text-center">
        <Link to="/login" className="gx-text-primary">
          Iniciar sesión
        </Link>
      </div>
    </PublicContainer>
  );
};

export default VerifyPhone;
