import { Modal } from "antd";
import { push } from "connected-react-router";

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_REMEMBER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from "../../constants/ActionTypes";
import { API, getError } from "../../util";

type DispatchType = ({
  type,
  payload,
}: {
  type: string | null;
  payload?: any;
}) => void;

/**
 * Ponemos la URL inicial
 */
export const setInitUrl = (url: string) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

/**
 * Inicio de sesión
 */
export const userLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("auth/login", { email, password });
      if (!response?.data?.token) {
        throw new Error("Error al iniciar sesión.");
      }
      localStorage.setItem("token", JSON.stringify(response.data.token));
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_TOKEN_SET, payload: response.data.token });
      dispatch({ type: USER_DATA, payload: response.data.data });
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

/**
 * Reinicio de sesión desde datos guardados
 */
export const userRelogin = (token: string, data: any) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      localStorage.setItem("token", JSON.stringify(token));
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: USER_TOKEN_SET, payload: token });
      dispatch({ type: USER_DATA, payload: data });
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

/**
 * Cerrar sesión
 */
export const userLogout = () => {
  return async (dispatch: DispatchType) => {
    Modal.confirm({
      title: "¿Quieres guardar tus datos de inicio de sesión?",
      content: "Podrás iniciar sesión de nuevo con un click",
      okText: "Guardar datos",
      cancelText: "No guardar",
      async onOk() {
        dispatch({ type: FETCH_START });
        localStorage.removeItem("token");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: LOGOUT_USER_REMEMBER_SUCCESS });
      },
      async onCancel() {
        dispatch({ type: FETCH_START });
        try {
          const response = await API.post("logout");
          if (!response?.data?.message) {
            throw new Error("Error al cerrar sesión.");
          }
        } catch (error) {
          const message = getError(error);
          dispatch({ type: FETCH_ERROR, payload: message });
        }
        localStorage.removeItem("token");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: LOGOUT_USER_SUCCESS });
      },
    });
  };
};

/**
 * Recuperar contraseña
 */
export const userRecovery = ({ email }: { email: string }) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("auth/recovery", { email });
      if (!response?.data?.message) {
        throw new Error("Error al recuperar contraseña.");
      }
      dispatch({ type: FETCH_SUCCESS });
      dispatch(push("/reset"));
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

/**
 * Resetear contraseña
 */
export const userReset = ({
  token,
  email,
  password,
  password_confirmation,
}: {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}) => {
  return async (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await API.post("auth/reset", {
        token,
        email,
        password,
        password_confirmation,
      });
      if (!response?.data?.message) {
        throw new Error("Error al restablecer contraseña.");
      }
      dispatch({ type: FETCH_SUCCESS });
      dispatch(push("/login"));
    } catch (error) {
      const message = getError(error);
      dispatch({ type: FETCH_ERROR, payload: message });
    }
  };
};

export const getUser = () => {
  return (dispatch: DispatchType) => {
    dispatch({ type: FETCH_START });
    API.post("auth/me")
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
